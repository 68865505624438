import React from "react"
import Layout from "../components/layout"
import InnerPageTwitterLayout from "../components/inner-page-twitter-layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

export default function PolicyConductSpectators() {
  return ( 
    <Layout>
        <section className="container">
            <h1>Code of Conduct - Parents and Spectators <a href="downloads/LCJFC Code of Conduct Parents-Spectators.pdf" target="_blank" title="download policy"><FontAwesomeIcon className="lcj-color" icon={faFileDownload}></FontAwesomeIcon></a></h1>
            <InnerPageTwitterLayout>
<div className="small">
<h5>We all have a responsibility to promote high standards of behaviour in the game </h5>
<p>LCJFC supports The FA’s Respect programme to ensure football can be enjoyed in a safe, positive environment. </p>
<p>Remember children’s football is a time for them to develop their technical, physical, tactical and social skills. Winning isn’t everything. </p>
<p>Play your part and observe The FA’s Respect Code of Conduct for spectators and parents/carers at all times. </p>
<p>
I will: 
<ul>
<li>Remember that children play for FUN </li>
<li>Applaud effort and good play as well as success </li>
<li>Always respect the match officials’ decisions </li>
<li>Remain outside the field of play and within the Designated Spectators’ Area (where provided) </li>
<li>Let the coach do their job and not confuse the players by telling them what to do </li>
<li>Encourage the players to respect the opposition, referee and match officials </li>
<li>Avoid criticising a player for making a mistake – mistakes are part of learning </li>
<li>Never engage in, or tolerate, offensive, insulting, or abusive language or behaviour. </li>
</ul>
</p>

<p>I understand that if I do not follow the Code, any/all of the following actions may be taken by my club, County FA, league or The FA: </p>
<p>I may be: 
<ul>
<li>Issued with a verbal warning from a club or league official </li>
<li>Required to meet with the club, league or County FA Welfare Officer </li>
<li>Required to meet with the club committee </li>
<li>Obliged to undertake an FA education course </li>
<li>Obliged to leave the match venue by the club </li>
<li>Requested by the club not to attend future games </li>
<li>Suspended or have my club membership removed </li>
<li>Required to leave the club along with any dependents. </li>
</ul>
</p>

<p>
In addition: 
<ul>
<li>The FA/County FA could impose a fine and/ or suspension on the club. </li>    
</ul>
</p>

</div> 
            </InnerPageTwitterLayout>
        </section>
    </Layout>
  );
}
